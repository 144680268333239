
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-emptybox {
  margin: 40px 0px;
}

@media (max-width: 991px){  

  .fc-empty {
    margin: 20px 0px;
  }
}
