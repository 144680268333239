$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-register-form {
	width: 80%;

	label {
		color: #fff;
		font-weight: normal;
	}
}

.fc-register {
    margin: 40px 0px;
}


.required-reg::after {
	content: " *";
	color: red;
}

.checkbox {
	margin: 0!important;
}

#btnShowTerms {
	cursor: pointer;
}

@media (max-width: 991px){  
	.fc-register-form {
		width: 100%;
		margin-left: 0px;
		padding: 0px;
	}
	.fc-register {
	  margin: 20px 0px;
	}
}
