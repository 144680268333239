
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.box-tracking .fc-pheader-con span {
    color: #000;
    font-size: 12px;
}

.fc-boxtrack {
	margin: 40px 0px;
}

.fc-subtrack {
	max-width: 450px;

	button {
		border-radius: 100px;
		background-color: $fc-orange;
		border: 1px solid $fc-orange;
		color: #fff;

		&:hover, &:active, &:visited {
			border: 1px solid $fc-orange !important;
			background-color: #fff !important;
			color: $fc-orange !important;
		}
	}
	
	.input-group-addon {
		border-top-left-radius: 100px;
    	border-bottom-left-radius: 100px;
	}

}


@media (max-width: 991px){  

  .fc-boxtrack {
	margin: 20px 0px;
}

}
