$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35;
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-cmas-cutoff {
    margin: 100px 0px!important;

    .styledtable {
        th {
            font-weight: normal;
        }
    }
}


@media (max-width: 768px) {

	.fc-pheader-con {
		h1,h2,p {
			display: block!important;
		}
	}
	.fc-cmas-cutoff {
		margin-top: 100px!important;
	}

}