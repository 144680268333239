
.moving-background {
	animation-name: moving-background;
	-webkit-animation-name: moving-background;

	animation-timing-function: linear;	
	-webkit-animation-timing-function: linear;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;

}

@-webkit-keyframes moving-background {
	from {background-position: 0 0;}
	to {background-position: -1648px 0;}
}

@keyframes moving-background  {
	from {background-position: 0 0;}
	to {background-position: -1648px 0;}
}
