$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-pricelist {
  margin: 40px 0px;
}

.fc-pricepanel {
  padding: 0px;

  a {
    text-decoration: none !important;
    display: block;
  }
}

.fc-panel {
  position: relative;
  cursor: pointer;

  .clickable {
    position: absolute;
      right: 10px;
      top: 5px;
  }
}





@media (max-width: 991px){  

  .fc-pricelist {
    margin: 20px 0px;
  }
}

