
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-testimonials {
	margin: 40px 0px;
}

.pbody {
	padding: 10px;
}

.fc-faqpanel {
	position: relative;
	cursor: pointer;

	.clickable {
		position: absolute;
    	right: 10px;
   		top: 5px;
	}
}

.testicon {
	padding: 20px;
	background-color: $fc-lblue;
	width: 100%;
	border-radius: 10px;
	margin-top: 20px;


	.icon {
		width: 15%;
		color: #fff;
		text-align: center;
	}

	.msg {
		color: #fff;
		text-align: center;
	}

	.msg > i {
		color: #00539f;
		font-size: 24px;
	}
	
	.user {
		color: #fff;
		padding-top: 10px;
		text-align: right;
		font-style: italic;
	}

	&:after {
		top: 100%;
	    left: 50%;
	    border: 1px solid;
	    content: " ";
	    height: 0;
	    width: 0;
	    position: absolute;
	    pointer-events: none;
	    border-color: rgba(136, 183, 213, 0);
	    border-top-color: #00aeef;
	    border-width: 10px;
	    margin-left: 210px;
	}

	.col-md-6 {
		margin-bottom: 20px;
	}


}

@media (max-width: 991px){  

  .fc-testimonials {
	margin: 20px 0px;
}

}
