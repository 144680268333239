
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-failed{
	margin: 40px 0px;
}

.fc-subheader span {
	color: #fff;
	background-color: $fc-orange;
	font-size: 24px;
}

.fc-pheader-con span {
	color: #fff;
	font-size: 24px;
	font-weight: 200;
	line-height: normal;

	strong {
		font-weight: 400;
	}
}

.fc-hbtn {
	margin-top: 10px;
}

.fc-hbtn img {
	width: 50px;
}

.fc-hbtn a {
    color: #fff;
    text-decoration: none;
}


.fc-pheader-img i {
	color: $fc-green;
	text-shadow: 0 1px 0 #fff;
	font-size: 200px;
}

.fc-hbtn.oth a {
	padding: 13px;
}

.cancelled-options {
	margin-bottom: 40px;
	p {color: #fff;}
	.fc-hbtn.poli a {padding: 0px !important;}
	.fc-hbtn.poli  {padding: 0px 13px 0px 0px !important;;}
}



@media (max-width: 991px){  

  .fc-failed {
		margin: 20px 0px;
	}
   
   

   

}
