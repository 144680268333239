
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-boxsystem {
	margin: 40px 0px;
}

.fc-tshead {
	width: 80%;
	display: table;

	.form-group {
		margin: 0px;
	}

	.img {
		display: table-cell;
		vertical-align: middle;
		width: 10%;
		img {width: 100%;}
	}

	.content {
		width: 70%;
		display: table-cell;
	}

	.row {
		margin: 0;
	}

	label {
		color: #fff;
		font-weight: normal;
	}

	.col-md-3, .col-md-7 {
		padding: 5px 0px 5px 0px;
	}
}

.datepicker {
	min-width: 250px !important;
}

.datepicker-days  .table-condensed {
	width: 100%;
}

.fc-delivery, .fc-pickup {
	padding: 10px;
	background-color: #fff;
	width: 100%;
	border: 1px solid #d8d8d8;
}

.fc-delivery, .fc-pickup {
	.img, .content {
		display: table-cell;
		vertical-align: middle;
	}

	.img {width: 40%; padding: 20px; img {width: 100%;}}
	.content {width: 60%}

	.fc-notif {
		margin: 0 !important;
	}

}



.fc-pickup > .fc-custom {
	background-color: $fc-lgray;
	border: 1px solid #d8d8d8;
	width: 100%;
	position: relative;

	h2 {
		color: #646464;
		margin-bottom: 5px;
	}

	.fc-cuspanel {
		padding: 10px;
		width: 100%;

		.img, .content {
			display: table-cell;
			vertical-align: middle;
		}

		.img {width: 20%; padding: 5px; img {width: 100%;}}
		.content {width: 80%}
	}

	.fc-cusbtn {
		position: absolute;
		right: 0px;
   		top: 5px;
	}

	.cusrem, .cusadd {
		float: left;
		padding: 5px 10px;
		font-size: 12px;
		cursor: pointer;
	}
	
	.fc-cuscon {
		border-top: 1px solid #d8d8d8;
		margin-top: 10px;
	}
}

.fc-formcustom {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}

.fc-systemform {
	display: table;
	//padding: 10px;
	width: 100%;

	.sys-label {
		display: table-cell;
		vertical-align: middle;
		width: 40%;
		line-height: 150%;

	}

	.sys-input {
		display: table-cell;
		vertical-align: middle;
		width: 60%;
	}

	.btn {
		//border-radius: 100px;
	}

	.btn-success, .btn-danger {
		// background-color: $fc-lblue;
		// border-color: #0088bb;
		    background-color: #f8f8f8;
   			border-color: #c4c4c4;
   			color: #646464;

		&:hover, &:focus, &[disabled]:hover {
			background-color: #c4c4c4 !important;
			border-color: #c4c4c4;
		}
	}

	&.orderbox {
		margin-bottom: 10px;
	}
}

.fc-pinfo {
	padding: 10px 26px;
    border: 1px solid #d8d8d8;

	.clearfix {
		margin: 8px 0;
	}

}

.custom-box .form-group {
	padding: 1px 1px;
}

@media (max-width: 991px){  

  .fc-boxsystem {
		margin: 20px 0px;
	}

   .fc-hbtn {
		margin-top: 5px;
	}

}

@media (max-width: 500px){  

  .fc-delivery .img, .fc-pickup .img {
  	display: none !important;
  }

  .fc-cusbtn {
  	position: relative !important;
  }

}

