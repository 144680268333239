$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35;
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-logo img {
    max-width: 200px;
    width: 100%;
}

.pointer-cursor {
    cursor: pointer;
}

body {
    padding-top: 0px;
    font-family: "Roboto", "sans-serif";
    color: #646464;
    font-size: 14px;
    line-height: 2;
    overflow-x: hidden !important;
}

.navbar-brand {
    padding: 15px auto !important;
}

.services-btn {
    display: block;
    padding: 40px 10px;
    transition: all 0.3s ease;
    background: none;
    border: 0px;
}

.about-btn {
    display: block;
    padding: 40px 10px;
    transition: all 0.3s ease;
    background: none;
    border: 0px;
}

a {
    outline: none;
}

hr.hspace {
    margin-top: 40px;
    margin-bottom: 40px;
    border: 0px;
}

h1,
h2,
h3 {
    font-family: "Roboto", 'sans-serif';
    color: $fc-lblue;
}

h1.fc-header {
    font-size: 32px;
    margin-top: 20px;
}

h2.fc-subheader {
    font-size: 20px;
    color: $fc-orange;
    margin-top: 0px;
    margin-bottom: 20px;
}

h2.fc-subheader-b {
    font-size: 20px;
    color: $fc-lblue;
    margin-top: 0px;
    margin-bottom: 20px;
}

.btxt {
    color: $fc-lblue;
}

.otxt {
    color: $fc-orange;
}

// NOTIFICATIONS
.fc-topnotif {
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 4px;
    font-size: 18px;

    //success
    &.success {
        background-color: $fc-green;
    }

    //danger
    &.danger {
        background-color: #e14444;
    }

    //warning
    &.warning {
        background-color: $fc-orange;
    }

    //notice
    &.info {
        background-color: #28c5ff;
    }

    .tn-msg span {
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.fc-notif {
    display: table;
    width: 100%;
    margin: 10px 0;
    line-height: 150%;

    div {
        padding: 10px;
        display: table-cell;
        vertical-align: middle;
    }

    .n-icon {
        width: 5%;
        text-align: center;
        font-size: 24px;
    }

    .n-con {
        width: 94%;
        padding-left: 20px;
        font-size: small;

        span {
            display: block;
            font-weight: bolder;
            text-transform: uppercase;
        }
    }

    .n-close {
        width: 1%;
        padding-left: 10px;
    }
}

.fc-notif.notice {
    border: 1px solid $fc-lblue;
    background-color: #e4f8ff;

    .n-icon {
        background-color: $fc-lblue;
        color: #fff !important;
    }

    span {
        color: $fc-lblue;
    }
}

.fc-notif.success {
    border: 1px solid #66b426;
    background-color: #e8ffd5;

    .n-icon {
        background-color: #66b426;
        color: #fff !important;
    }

    span {
        color: #66b426;
    }
}

.fc-notif.warning {
    border: 1px solid #e58e0b;
    background-color: #fff4db;

    .n-icon {
        background-color: #e58e0b;
        color: #fff !important;
    }

    span {
        color: #e58e0b;
    }
}

.fc-notif.error {
    border: 1px solid #e14444;
    background-color: #fff2f2;

    .n-icon {
        background-color: #e14444;
        color: #fff !important;
    }

    span {
        color: #e14444;
    }
}

.b-tracking {
    background-color: #ffba00!important;
    padding: 30px 0px;
    margin: 100px 0 0 0!important;

    .fc-header-track {
        max-width: 75%;
        margin: auto !important;

        span {
            border-radius: 5px 0px 0px 5px!important;
            background-color: #0954a0!important;
            color: #ffffff;
        }
        button {
            border-radius: 0px 5px 5px 0px!important;
            background-color: #0954a0!important;
            border-color: #0954a0!important;
            &:hover {
                border-color: #0954a0!important;
                background-color: #ffffff!important;
                color: #0954a0!important;
            }
        }
    }
}

.fc-pheader-con a {
    font-weight: 600;
    color: #fff;
    text-decoration: underline;
}

.unset-header {
    position: inherit !important;

    .fc-pheader-con {
        padding-top: 120px;
    }
}

.pinherit {
    position: inherit !important;
}

.mt20 {
    margin-top: 120px !important;
}
.mb120 {
    margin-bottom: 120px!important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.m-0 {
    margin: 0px!important;
}

.centered {
    text-align: center;
}

.f-right {
    float: right;
}

.f-left {
    float: left;
}

.m-right {
    margin-right: 10px !important;
}

.m-left {
    margin-left: 10px !important;
}

.m-top {
    margin-top: 10px !important;
}

.mlg-right {
    margin-right: 30px !important;
}

.mlg-left {
    margin-left: 30px !important;
}

.mlg-top {
    margin-top: 30px !important;
}

.p-right {
    padding-right: 10px !important;
}

.p-left {
    padding-left: 10px !important;
}

.plg-right {
    padding-right: 30px !important;
}

.plg-left {
    padding-left: 30px !important;
}

.plg-top {
    padding-top: 30px !important;
}

.p-top {
    padding-top: 10px !important;
}

.wshadow {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.10);
}

.noBg {
    background-image: none !important;
}

.onMobile,
.onMobile-sm {
    display: none;
}

.onDesktop,
.onDesktop-sm {
    display: block;
}

.fc-ad-btn {
    padding: 10px 20px 10px 20px;
    background-color: none;
    font-size: 12px;
    border-radius: 100px;
    border: 1px solid #fff;
    color: #fff;
    display: inline-block;
    transition: all 0.3s ease;
    text-transform: uppercase;
    margin-top: 10px;
    cursor: pointer;
}

.fc-ad-btn:hover {
    color: $fc-orange;
    background-color: #fff;
    text-decoration: none;
}

.pb-0 {
    padding-bottom: 0px !important;
}

button.fc-hbtn {
    font-size: 12px;
    border-radius: 100px;
    border: 2px solid #fff;
    color: #fff;
    background: none;
    transition: all 0.3s ease;
    text-transform: uppercase;
    position: relative;

    &:hover {
        border-color: $fc-orange;
        color: #fff;
        background-color: $fc-orange;
    }

    a {
        padding: 10px 20px 10px 20px;
        display: block;
    }
}

button.fc-obtn {
    padding: 10px 20px 10px 20px;
    background-color: $fc-orange;
    font-size: 12px;
    border-radius: 100px;
    border: 2px solid $fc-orange;
    color: #fff;
    transition: all 0.3s ease;
    text-transform: uppercase;
    margin-bottom: 10px;

    &:hover {
        background-color: #fff;
        color: $fc-orange;
    }
}

button.fc-bbtn {
    padding: 10px 20px 10px 20px;
    background-color: $fc-lblue;
    font-size: 18px;
    border-radius: 100px;
    border: 2px solid $fc-lblue;
    color: #fff;
    transition: all 0.3s ease;

    &:hover {
        background-color: #fff;
        color: $fc-lblue;
    }
}


/* --- FOREX CARGO NAV BAR --- */

.fc-logo {
    height: auto !important
}

.navbar-separator {
    height: 134px;
    transition: all ease 0.5s;

    &.shrink {}
}

.nav-up {
    top: -134px;
}

.fc-navbar {
    background-color: #fff;
    border: none;
    margin-bottom: 0px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    box-shadow: 1px 1px 11px rgba(0, 0, 0, 0.12);

    .fc-navlinks li {
        // padding: 20px 0px;
    }

    .imp-link {
        padding: 20px !important;
        margin-top: 20px !important;
    }

    .fc-navlinks li>a {
        display: block;
        padding: 40px 8px;
        transition: all 0.3s ease;
    }

    .fc-navlinks li>a:hover {
        background-color: $fc-lgray;
        color: #9d9d9d;
    }

    .dropdown-menu {
        min-width: 210px;

        a {
            &:hover {
                color: #ffffff !important;
                background-color: #26a9e1 !important;
            }
        }
    }

    .dropdown-submenu {
        a {
            &:after {
                margin-right: 0;
            }

            &:hover {
                color: #ffffff !important;
                background-color: #26a9e1 !important;
            }
        }


    }

    .fc-logo {
        height: 155px;
        margin: auto !important;
    }

    img {
        transition: all ease 0.5s;
    }

    &.shrink {
        .fc-logo img {
            width: 60% !important;
        }

        .navbar-brand {
            padding: 9px 0px !important;
        }

        .fc-navlinks li>a {
            padding: 20px 10px;
            font-size: 12px;
        }

        .imp-link {
            padding: 10px !important;
            margin-top: 10px !important;
            text-align: center;
        }

        .fc-subnav ul li a {
            padding: 2px 10px !important;
            font-size: 11px !important;
        }

        .fc-btoggle {
            margin: 0px;
        }
    }

    .fc-btoggle {
        color: $fc-orange;
        background: #FFF;
        border: 0px;
        font-size: 21px;

        &:hover,
        &:focus {
            background: #fff;
            color: $fc-orange !important;
        }
    }
}

.fc-subnav {
    background-color: $fc-lblue;
    color: #fff;
    font-size: 12px;
    text-align: center;

    ul {
        list-style: none;
        margin-left: 0px;
        margin-bottom: 0px;

        li {
            display: inline-block;
            transition: all 0.3s ease;

            &:hover {
                background-color: #2cc4fd;
            }

            a {
                padding: 5px 15px;
                display: block;
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

.fc-subnav-collapse {
    display: none;
    font-size: 14px;
    font-weight: bolder;
    padding: 10px;
    cursor: pointer;
    background-color: $fc-lblue;
}

a.imp-link {
    background-color: $fc-orange;
    border: 2px solid $fc-orange;
    border-radius: 100px;
    color: #fff !important;
    font-weight: bolder;
    transition: all 0.3s ease;

    &:hover {
        color: $fc-orange !important;
    }
}

.fc-login {
    width: 300px;
    padding: 0px;

    .lr-con {
        margin: 10px;
    }

    button {
        width: 100%;
        background-color: $fc-lblue;
        color: #fff;
        font-weight: bold;
        border: 0px;
        padding: 10px;
    }
}

.opt-center {
    margin: 0 auto;
    position: relative;
    display: block !important;
}

.fc-table {
    display: table;
    width: 100%;

    .fc-tablecell {
        display: table-cell;
        vertical-align: middle;
    }
}

.tc-20 {
    width: 20%;
}

.tc-25 {
    width: 25%;
}

.tc-35 {
    width: 35%;
}

.tc-45 {
    width: 45%;
}

.tc-50 {
    width: 50%;
}

.tc-75 {
    width: 75%;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    background-color: #fff;
    border: 2px solid $fc-orange;
    color: $fc-orange !important;
}

.navbar-inverse .navbar-nav>.open>a.services-btn,
.navbar-inverse .navbar-nav>.open>a.services-btn:focus {
    border: 0px !important;
    background-color: $fc-lgray;
}

.navbar-inverse .navbar-nav>.open>a.about-btn,
.navbar-inverse .navbar-nav>.open>a.about-btn:focus {
    border: 0px !important;
    background-color: $fc-lgray;
}

.services-dropdown {
    padding: 0px !important;
    border: 0px;
}

.services-dropdown li a {
    padding: 10px !important;
    text-align: center;
    color: #9d9d9d;
    border-bottom: 1px solid $fc-lgray;

    &:hover {
        color: $fc-orange !important;
    }
}

.about-dropdown {
    padding: 0px !important;
    border: 0px;
}

.about-dropdown li a {
    padding: 10px !important;
    text-align: center;
    color: #9d9d9d;
    border-bottom: 1px solid $fc-lgray;

    &:hover {
        color: $fc-orange !important;
    }
}

// TABLE / TABS / FORMS
label {
    margin-top: 7px;
    margin-bottom: 0px;
}

.fc-navtab-dropdown {
    padding: 10px;
    width: 100%;
    background-color: $fc-lblue;
    color: #fff !important;
    font-weight: bold;
    cursor: pointer;
}

.fc-navtab {
    a {
        border-radius: 0px !important;
    }

    .active a {
        border-top: 2px solid $fc-lblue !important;
        color: $fc-lblue !important;
    }
}

.fc-navtab-sub {
    a {
        border-radius: 0px !important;
    }

    .active a {
        border-top: 2px solid $fc-orange !important;
        color: $fc-orange !important;
    }
}

.tab-content {
    margin-top: 5px;
}

table.styledtable {
    margin: 0 auto;
    margin-top: 5px;
    border-collapse: collapse;
    font-weight: 100;
    background: #f1f3f5;
    text-rendering: optimizeLegibility;
    border-radius: 5px;
    border: 1px solid #D9E8F6;
}

table.styledtable thead th {
    font-weight: 600;
}

table.styledtable thead th,
table.styledtable tbody td {
    padding: 15px 15px;
}

table.styledtable tbody td {
    padding: 10px 15px 10px 15px;
    font-size: 13px;
    background: #ffffff;
}

table.styledtable tbody tr:not(:last-child) {
    border-top: 1px solid #D9E8F6;
    border-bottom: 1px solid #D9E8F6;
}

// @media screen and (max-width: 1200px) {
//     .fc-navbar {
//         .navbar-header {
//             float: none;
//         }
//         .navbar-toggle {
//             display: block;
//         }
//         .navbar-collapse {
//             border-top: 1px solid transparent;
//             box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
//         }
//         .navbar-collapse.collapse {
//             display: none !important;
//         }
//         .navbar-nav {
//             float: none !important;
//             margin: 7.5px -15px;
//             >li {
//                 float: none;
//                 >a {
//                     padding-top: 10px;
//                     padding-bottom: 10px;
//                 }
//             }
//         }
//     }
// }
@media screen and (max-width: 600px) {
    table.styledtable caption {
        background-image: none;
    }

    table.styledtable thead {
        display: none;
    }

    table.styledtable tbody td {
        display: block;
        padding: 10px 15px 10px 15px;
        line-height: 30px;
    }

    table.styledtable tbody tr td:first-child {
        background: #f1f3f5;
        font-weight: bold;
        border-bottom: 1px solid #D9E8F6;
        line-height: 20px;
        font-size: 14px;
    }

    table.styledtable tbody td:before {
        content: attr(data-th);
        font-weight: bold;
        display: block;
        width: 6rem;
    }
}

.table>thead>tr>th {
    background: #eaeaea;
    padding: 15px;
}

// SUB PAGES
.fc-pheader {
    height: auto;
    width: 100%;
    position: relative;
    background: #00539f;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00539f 0%, #00abea 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00539f 0%, #00abea 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00539f 0%, #00abea 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#00539f', endColorstr='#00abea', GradientType=0);
    /* IE6-9 */
}

.fc-pheader-con {
    display: table;
    height: 100%;
    width: 100%;
    margin: 0 auto;

    p {
        color: #fff;
    }

    .fc-pheader-img {
        display: table-cell;
        vertical-align: middle;
        width: 35%;
        padding-right: 40px;

        img {
            width: 100%;
        }
    }

    .fc-pheader-text {
        display: table-cell;
        vertical-align: middle;
        width: 65%;
        padding-right: 20px;

        h1 {
            color: #fff;
        }

        h2 {
            color: $fc-orange
        }
    }
}

.fc-footertop {
    background-color: #323232;
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;

    img {
        width: 150px;
        padding-bottom: 25px;
    }

    h2 {
        font-size: 18px;
        color: $fc-lgray;
    }

    i {
        font-size: 20px;
        margin-right: 10px;
        display: inline-block;
    }

    .fc-contact td {
        border: 0px;
    }

    a {
        color: #646464;
    }

    .fc-sabout {
        margin-bottom: 30px;
    }
}

.fc-footerbottom {
    background-color: #1e1e1e;
    padding: 20px 10px;

    p {
        font-size: 10px;
    }
}

@media (max-width: 768px) {
    .fc-navlinks {
        position: absolute;
        width: 100%;
        background: white;
        border-top: 1px solid $fc-lgray !important;
        box-shadow: rgba(0, 0, 0, 0.35) 1px 4px 12px;

        li {
            text-align: center!important;
        }

    }

    .b-tracking {
        .fc-header-track {
            max-width: 100%;
        }
    }


    .fc-navbar {
        .navbar-toggle {
            margin-bottom: auto !important;
            margin-top: 20px !important;
        }

        &.shrink {
            .navbar-toggle {
                margin-bottom: auto !important;
                margin-top: 12px !important;
            }
        }
    }

    .navbar-separator {
        height: 147px !important;
    }

    .fc-navbar ul.nav {
        margin-top: 0px !important;
    }

    .fc-navbar .fc-navlinks li>a {
        padding: 20px;
    }

    .fc-subnav .nav-slide>.navbar-right {
        background-color: #fff !important;
        padding: 0px !important;
    }

    .fc-navbar .imp-link {
        margin-top: 0px !important;
    }

    .fc-subnav {
        text-align: left;

        ul {
            margin-left: 0px;
            margin-bottom: 0px;
            padding-top: 10px;
            padding-left: 10px;

            li {
                display: block !important;
                padding: 5px 5px;
                border-bottom: 1px solid #eee;

                &:hover {
                    background-color: $fc-lgray;
                }

                a {
                    text-decoration: none;
                    color: #9d9d9d;
                    display: block;
                }
            }
        }
    }

    .fc-subnav-collapse {
        display: block;
    }

    .onMobile-sm {
        display: block;
    }

    .onDesktop-sm {
        display: none;
    }
}

@media (max-width: 340px) {
    .fc-logo img {
        width: 145px !important;
    }

    .navbar-separator {
        height: 124px !important;
    }

    .mobile-view {
        display: block;
    }

    .desktop-view {
        display: none;
    }
}

@media (max-width: 991px) {
    .navbar-separator {
        height: 267px;
    }

    .onDesktop {
        display: none;
    }

    .onMobile {
        display: block;
    }

    .fc-tablecell {
        display: block !important;
    }

    .tc-20,
    .tc-25,
    .tc-35,
    .tc-45,
    .tc-50,
    .tc-75 {
        width: 100%;
    }

    .fc-pheader-img {
        display: none !important;
    }

    .fc-pheader-con {
        padding: 10px;
    }

    // .fc-pheader-text {
    //  text-align: center;
    // }
    .mobile-view {
        display: block;
    }

    .desktop-view {
        display: none;
    }
}

// CAROUSEL
.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }

        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
        background: none !important;
    }
}

html,
body,
.carousel,
.carousel-inner,
.carousel-inner .item {
    height: 100%;
}


/*
 * Multi level dropdown
*/

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
}

// .dropdown-submenu:hover>.dropdown-menu {
//     display: block;
// }
.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
    color: #f1ac35 !important;
}

// .dropdown-submenu:hover>a:after {
//     border-left-color: #fff;
// }
.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

.mobile-view {
    display: none;
}

// img-airfreight
.top-header {
    .fc-pheader {
        top: 120px !important;
        background: none;
    }
}


.fc-airfreight {
    .img-logo {
        max-width: 250px;
        margin: auto;
    }

    .air-header-title {
        h1 {
            font-size: 5rem;
            font-weight: 900;
            margin: 0;
        }

        h2 {
            font-size: 8rem;
            font-weight: 900;
            margin: 0;
        }
    }

    .air-content {
        .rate-bg {
            background-image: url('/images/pages/rate-bg.png') !important;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center center;
        }

        .rate-header {
            color: #e11a51 !important;
            font-weight: 900 !important;
            font-size: 2rem;
            padding: 10px;
            background-color: #ecb52a;
        }

        .card-body {
            padding: 10px 20px !important;
            background-color: #1d7cb2 !important;
            color: #ffffff !important;

            h5 {
                font-size: 2.5rem;
                font-weight: 900;
            }

            .desc {
                font-weight: 800;
            }

            p {
                font-size: 1.4rem !important;
            }
        }
    }

    .mt-1 {
        margin-top: 10px;
    }
}