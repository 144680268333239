
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-about {
	margin: 40px 0px;
}

