$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35;
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-shipsched {
    margin: 40px 0px;
}

.fc-ship-bg {
    background-image: url('/images/pages/fc-shipping.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
   

    .fc-pheader-text {
    	width: 50%!important;
    }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

	.fc-ship-bg {
		 background: linear-gradient(to bottom, #00539f 0%, #00abea 100%)!important;
	}
}

@media (max-width: 991px) {

    .fc-shipsched {
        margin: 20px 0px;
    }

}