@import 'https://fonts.googleapis.com/css?family=Montserrat|Roboto';
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import 'node_modules/font-awesome/scss/font-awesome.scss';

@import './custom.scss';
@import './additional-styles.scss';
@import './about-us.scss';
@import './airfreight.scss';
@import './animation.scss';
@import './boxsystem.scss';
@import './boxsystem-done.scss';
@import './boxsystem-front.scss';
@import './cmas-cutoff.scss';
@import './datepicker.scss';
@import './dda.scss';
@import './emptybox.scss';
@import './failed-poli.scss';
@import './faq.scss';
@import './gallery.scss';
@import './home.scss';
@import './box-tracking.scss';
@import './login.scss';
@import './news.scss';
@import './oddbox.scss';
@import './pages.scss';
@import './pricelist.scss';
@import './register.scss';
@import './shipping-schedule.scss';
@import './success-poli.scss';
@import './testimonials.scss';
