$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35;
$fc-lgray: #f8f8f8;
$fc-green: #b2d708;

.fc-advert {
    background-color: none;
    float: left;
    width: 450px;
    height: 500px;
    position: relative;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
}

.fc-ad {
    height: 500px;
    padding: 20px 80px;
    position: relative;

    img {
        margin: 0 auto;
        max-width: 270px;
        width: 100%;
    }

    .ctg-txt {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 5px 15px;
        color: #fff;
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.17);
        border-bottom-right-radius: 10px;
    }

    .fc-ad-content {
        text-align: center;

        h1 {
            color: #fff;
            font-size: 24px;
        }

        p {
            color: #fff
        }
    }

}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", 'sans-serif' !important;
}

.control-con {
    width: auto;
    height: 100px;
    position: absolute;
    bottom: -50px;
    right: 20px;

    .control-left,
    .control-right {
        text-decoration: none;
        padding: 10px;
        color: #fff !important;
        font-size: 20px;
    }

    .control-left:hover,
    .control-right:hover {
        color: $fc-orange !important;
    }
}

.fc-ad.news-ad {
    background: $fc-lblue;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient($fc-dblue, $fc-lblue);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient($fc-dblue, $fc-lblue);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient($fc-dblue, $fc-lblue);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient($fc-dblue, $fc-lblue);
    /* Standard syntax */
}

.fc-ad.imp-ad {
    background: $fc-orange;
    /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(#f17a35, $fc-orange);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#f17a35, $fc-orange);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#f17a35, $fc-orange);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#f17a35, $fc-orange);
    /* Standard syntax */
}

.news-carousel {
    box-shadow: 5px -15px 62px rgba(0, 0, 0, 0.44);
}

.fc-banner {
    background-image: url('/images/fc-banner.jpg');
    width: calc(100% - 450px);
    float: right;
    height: 500px;
    animation-duration: 120s;
    -webkit-animation-duration: 120s;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;


    .fc-header-con {
        display: table;
        height: 100%;
        width: 100%;
        padding: 0px 20px;
    }

    .container {
        height: 100%;
    }

    .fc-header-track {
        max-width: 500px;
        // margin: 0 auto;
        padding-top: 10px;

        span {
            border-radius: 100px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-color: #fff;
        }

        input {
            border-radius: 100px;
            height: 45px;
            font-size: 18px;
            border: 1px solid #fff;

        }

        button {
            border-radius: 100px;
            background-color: $fc-orange;
            border: 1px solid $fc-orange;
            color: #fff;
            height: 45px;
            font-size: 18px;
            transition: all 0.3s ease;
            padding-right: 20px;

            &:hover {
                background-color: #fff;
                border: 1px solid #e8b944;
                color: $fc-orange;
            }
        }

    }
}

#track-result .modal-header {
    background: $fc-lblue;
    color: #fff;
    overflow: hidden;
}

.modal-content {
    overflow: hidden;
}

.fc-header-content {

    display: table-cell;
    vertical-align: middle;
    height: 100%;
    text-align: left;


    h1 {
        color: #fff;
        font-weight: normal;
        font-size: 50px;
        margin-top: 0px;
    }

    h2 {

        font-size: 21px;
        color: #fff;
    }

    p {
        color: #fff;
        font-size: 18px;
        line-height: 150% !important;
    }

    a {
        text-decoration: underline;
        color: #fff;
        font-size: 12px;
        margin-top: 10px;
        display: block;
    }
}

.fc-boxopt-con {
    width: 100%;
    position: relative;
    height: auto;
    background-color: $fc-lgray;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dadada;

    .fc-boxopt {
        display: table;
        width: 50%;

        .opt-center {
            max-width: 500px;
        }

        .wLine {
            border-right: 1px solid #cacaca;
        }

        div {
            display: table-cell;
            vertical-align: middle;
        }

        .fc-boximg {
            width: 15%;
        }

        .fc-boxopt-text {
            padding-left: 20px;
            width: 85%;

            h1 {
                font-size: 21px;
                margin-bottom: 5px;
                margin-top: 0px;
            }
        }
    }
}


.fc-schedmap {
    margin-top: 120px;
    margin-bottom: 40px;

    img {
        width: 300px;
        margin-top: 20px;
    }
}

.m-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.fc-shipping {
    .tc-45 {
        color: $fc-lblue;
    }

    .tc-20 {
        font-weight: 600;
    }
}

.hovercon {
    margin: 0 auto;
    position: relative;
    width: 300px;
    line-height: 150% !important;
}

.dmanila,
.dluzon,
.dvisayas,
.dmindanao {
    font-family: "Montserrat", "sans-serif";
    color: #fff;
    position: absolute;
    padding: 10px 20px 10px 20px;
    border-radius: 100px;
    transition: all 0.5s ease;
    font-size: 16px;
    text-align: center;
    transform: scale(0);
}

.dmain {
    transform: scale(1) !important;
}

.dmanila {
    background-color: $fc-green;
    top: 161px;
    left: -95px;
    transform-origin: right;
}

.dluzon {
    background-color: $fc-orange;
    top: 110px;
    left: 125px;
    transform-origin: left;
}

.dvisayas {
    background-color: $fc-orange;
    top: 243px;
    left: 5px;
    transform-origin: right;

}

.dmindanao {
    background-color: $fc-orange;
    top: 333px;
    left: 56px;
    transform-origin: right;
}




.hmanila,
.hluzon,
.hvisayas,
.hmindanao {
    position: absolute;
    border: 2px solid #fff;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    transition: all 0.5s ease;

    &:hover {
        cursor: pointer;
        border: 7px solid #fff;
        transform: scale(2);
    }
}

.hmanila {
    background-color: $fc-green;
    top: 182px;
    left: 94px;
}

.hluzon {
    background-color: $fc-orange;
    top: 130px;
    left: 90px;
}

.hvisayas {
    background-color: $fc-orange;
    top: 264px;
    left: 190px;
}

.hmindanao {
    background-color: $fc-orange;
    top: 354px;
    left: 240px;
}

.fc-abt {
    background: #00AEEF;
    color: #fff;
    overflow: hidden;
}


.fc-testi {
    margin-top: 40px;
    margin-bottom: 0px;
    background-color: $fc-dblue;
    max-height: 500px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00539f+0,00abea+100 */
    background: #00539f;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00539f 0%, #00abea 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00539f 0%, #00abea 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00539f 0%, #00abea 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00539f', endColorstr='#00abea', GradientType=0);
    /* IE6-9 */
    text-align: center;

    h1 {
        color: #fff !important;
        margin: 20px 0px 10px 0px;
    }

    .msg {
        overflow: hidden;
        position: relative;
        padding: 10px;
    }

    p {
        color: #fff !important;
        overflow: auto;
        height: 100%;
        width: 100%;
    }

    .carousel-indicators {
        position: relative;
        top: 0px !important;
    }

    .container {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .item {
        span {
            color: $fc-orange !important;
            font-style: italic;
            margin-bottom: 20px;
            display: block;

            &:before {
                content: '- ';
            }
        }
    }
}

.fc-news {
    // margin-top: 40px;
    margin-bottom: 0px;
    background-color: $fc-dblue;
    height: auto;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00539f+0,00abea+100 */
    background: #00539f;
    /* Old browsers */
    background: -moz-linear-gradient(top, #00539f 0%, #00abea 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #00539f 0%, #00abea 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #00539f 0%, #00abea 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00539f', endColorstr='#00abea', GradientType=0);
    /* IE6-9 */
    text-align: center;

    .container {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    h1 {
        color: #fff !important;
        margin: 20px 0px 20px 0px;
    }

    h2 {
        font-size: 2em;
        margin-top: 0px;

        a {
            color: #f1ac35;
        }
    }

    p {
        color: #fff !important;
        overflow: auto;
        height: 100%;
        width: 100%;
    }
}

.fc-boxes {
    background-image: url("../images/fc-forexcargobg.jpg");
    background-repeat: no-repeat;
    background-size: contain;

    .fc-boxopt-con {
        background: none !important;
        padding: 40px 0px;
        width: 100%;

        img {
            width: 100%;
        }

        .fc-boximg {
            width: 30%
        }

        .fc-boxopt-text {
            width: 70%;
        }

        .fc-boxcon {
            width: 100%;
        }

        .fc-boxcolcon {
            position: relative;
            margin: 0 auto;
        }
    }

    .fc-boxtable {
        background: #fff;
        border-radius: 5px;
        overflow: hidden;
        width: auto;
        margin: 0 auto;


        img {
            width: 140px;
        }

        .fa-check {
            color: $fc-green;
        }

        td {
            border: 0px;
        }
    }


    .fc-boxcol {
        // width: 50%;
        float: left;
        padding: 0 15px;
        position: relative;
        width: 50%;

    }

    .fc-regcol {
        padding: 25px;
        background: #898989;
        text-align: center;

        h1 {
            color: #fff !important
        }

        p {
            color: $fc-lgray !important;
            font-weight: normal;
        }
    }

    .fc-premcol {
        padding: 25px;
        background: $fc-lblue;
        text-align: center;

        h1 {
            color: #fff !important
        }

        p {
            color: $fc-lgray !important;
            font-weight: normal;
        }
    }
}



@media (max-width: 768px) {

    .fc-header-content {

        h1 {
            color: #fff;
            font-weight: normal;
            font-size: 35px;
        }

        h2 {

            font-size: 14px;
            color: #fff;
        }

        p {
            color: #fff;
            font-size: 12px;
        }

        a {
            text-decoration: underline;
            color: #fff;
            font-size: 12px;
            margin-top: 10px;
            display: block;
        }
    }

    .fc-boxopt {
        width: 100% !important;
        margin-bottom: 25px;

        .wLine {
            border: 0px !important;
        }
    }

    .fc-boxes .fc-boxcol {
        width: 100%;
    }

    .fc-boxes .fc-boxtable {
        width: 100%;
    }

    .fc-boxes {
        background-image: none;
    }

    .fc-boxtable>tbody>tr>td {
        display: block;
        width: 100% !important;
    }


}



.fc-carousel .news1 {
    padding: 10px;
    background: #F1AC35;
    position: relative;
}

.fc-news-table {
    display: table;
    position: relative;
    width: 100%;

    .newslink {
        border-radius: 100px;
        padding: 10px 20px;
        background: #fff;
        color: $fc-orange;
    }

    .fc-col1 {
        display: table-cell;
        width: 30%;
        position: relative;

        .img-float {
            position: absolute;
            overflow: hidden;
            z-index: 1000;
            right: 20px;
            top: -30px;
            height: 83px;
            width: 300px;

            img {
                width: 100%;
            }
        }
    }

    .fc-col2 {
        display: table-cell;
        width: 50%;

        h2.fc-subheader {
            font-size: 18px !important;
            color: #fff;
            margin-bottom: 0px;
        }

        p {
            color: #fff;
            font-size: 12px;
            margin-bottom: 0px;
        }
    }

    .fc-col3 {
        display: table-cell;
        width: 20%;
        vertical-align: middle;
    }
}





@media (max-width: 430px) {

    .fc-col2 {
        width: 100% !important;
        display: block !important;
        text-align: center;
        padding-bottom: 10px;
    }

    .fc-col3 {
        width: 100% !important;
        display: block !important;
        text-align: center;
    }

    .fc-ad-content {
        h1 {
            font-size: 20px !important;
            margin: 0px;
        }

        p {
            font-size: 12px;
            margin-bottom: 0px;
        }

        .fc-ad-btn {
            padding: 5px 10px;
            font-size: 12px;
            margin-top: 0px;
        }

    }
}

/*
NEW HOME BANNER
*/

hr.style-one {
    border: 0;
    height: 1px;
    background: #333;
    background-image: linear-gradient(to right, #cacaca, #cacaca, #cacaca);
    margin: 0 !important;
}

.fc-cargo {
    max-height: 500px !important;
    height: 100%;
    width: 100%;

    .carousel-control {
        width: 5% !important;
    }

    .slider-content {
        position: absolute;
        top: 200px;
        z-index: 1;
        bottom: 150px;


        h1 {
            font-size: 60px;
            font-weight: 900;
            text-shadow: 2px 1px 2px #000 !important;
            color: #fff;
        }

        a {
            text-decoration: none;
            color: #ff9933;
            font-weight: 900;
            text-shadow: 2px 2px 2px #000 !important;
        }
    }

    .slide1 {
        background-image: url('/images/banner/slider8-1.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        /* background-size: cover;

 */
        width: 100%;

        .long-content {
            font-size: 3rem;
        }
    }

    .slide2 {
        background-image: url('/images/banner/air.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        width: 100%;
    }

    .slide3 {
        background-image: url('/images/banner/sea.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide4 {
        background-image: url('/images/banner/slider4-1.jpg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide5 {
        background-image: url('/images/banner/slider5-1.jpg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide6 {
        background-image: url('/images/banner/slider9-1.jpg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        cursor: pointer;

        .long-content {
            font-size: 3rem;
        }
    }

    .slide7 {
        background-image: url("/images/banner/slider11-1.jpg") !important;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;

        h1 {
            font-size: 60px !important;
        }

        .slider-content {
            a {
                text-shadow: none !important;
            }
        }

        h4 {
            font-size: 30px;
            font-weight: 800;
            color: #000;
        }

        width: 100%;

        .long-content {
            font-size: 3rem;
        }
    }

    .slide8 {
        background-image: url('/images/banner/slider11-1.jpg');
        background-position: right center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-forex-de-mayo-2022 {
        background-image: url('/images/banner/forexdemayo-slider-2022.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-forex-christmas {
        background-image: url('/images/banner/forex-christmas.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-forex-giveaway {
        background-image: url('/images/banner/forex-giveaway.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-covid-safe {
        background-image: url('/images/banner/Forex-Cargo-Covid-Safe-Balikbayan-Box.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-localized {
        background-image: url('/images/banner/ForexCargo-localized-number-slider.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-balikbayanbox {
        background-image: url('/images/banner/balikbayan-box-businessasusual-australia-lockdown-slider.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    .slide-xmas-cutoff {
        background-image: url('/images/banner/xmascutoff2022-slider.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }

    .slide-xmas-promo {
        background-image: url('/images/banner/fc-xmaspromo-desktop.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    .slide-job {
        background-image: url('/images/banner/forexcargo-job-opening-forex-team.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    .slide-xmas-closure {
        background-image: url('/images/banner/forex-cargo-holiday-closure-20212.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    .xmasoff {
        background-image: url('/images/banner/xmascutoff2022-slider.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    .slide-xmas-love {
        background-image: url('/images/banner/fc-balikbayanbox-sendlove-sliderNEW.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    .slide-box-promo {
        background-image: url('/images/banner/cargo-promo-luggagescale.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
    .slide-box-price {
        background-image: url('/images/banner/cargo-price-increase-advisory-slider.jpg');
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }
}

.b-tracking {
    margin: 120px 0 20px 0;

    .fc-header-track {
        margin: 0 auto;


        span {
            border-radius: 100px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;

        }

        input {
            border-radius: 100px;
            height: 45px;
            font-size: 18px;


        }

        button {
            border-radius: 100px;
            background-color: $fc-orange;
            border: 1px solid $fc-orange;
            color: #fff;
            height: 45px;
            font-size: 18px;
            transition: all 0.3s ease;
            padding-right: 20px;

            &:hover {
                background-color: #fff;
                border: 1px solid #e8b944;
                color: $fc-orange;
            }
        }

    }
}

table {
    width: 100%;
    border-collapse: collapse;
}

/* Zebra striping */
tr:nth-of-type(odd) {
    background: transparent;

}

th {
    background: #333 !important;
    color: white;
    font-weight: bold;
}

td,
th {
    padding: 6px;
    text-align: left;
}

.services {

    a {
        text-decoration: none;
        color: #ff9933;
    }

    h1,
    h2,
    h3 {
        font-family: "Roboto", 'sans-serif';
    }

    padding: 20px 0 20px 0;
}

.fc-cargo,
.fc-header,
.fc-pheader {
    top: 100px;

}

.logincon {
    top: 0px !important;
}

.fc-about,
.fc-testimonials,
.fc-pricelist,
.fc-airfreight,
.fc-faq,
.fc-emptybox,
.fc-dda,
.fc-boxtrack,
.fc-oddbox {
    margin: 120px 0 20px 0 !important;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

    /* Force table to not be like tables anymore */
    .fc-table {

        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
        }

        td {
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50% !important;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }

        .air-serv {
            td:nth-of-type(1):before {
                content: "SCHEDULE";
            }

            td:nth-of-type(2):before {
                content: "CUTOFF:";
            }

            td:nth-of-type(3):before {
                content: "REMARKS:";
            }

            td:nth-of-type(4):before {
                content: "FLYOUT:";
            }
        }

        .sea-serv {
            td:nth-of-type(1):before {
                content: "LOADING:";
            }

            td:nth-of-type(4):before {
                content: "SHIP DEPARTS:";
            }
        }

    }

    .fc-testi {
        max-height: 100% !important;
    }

    .b-tracking,
    .services,
    .fc-boxopt-con {
        text-align: center !important;
    }

    .m-align {
        text-align: center !important;
    }

    /* banner */
    .fc-cargo {
        .slider-content {
            position: relative;
            top: 150px;
            bottom: 150px;
            text-align: center;

            h1 {
                font-size: 40px !important;
            }
        }

        .slide1 {
            background-image: url('/images/banner/slider8-1.jpg');
            background-position: left center;
        }

        .slide2 {
            background-image: url('/images/banner/air-1.jpg');
            background-position: center center;
        }

        .slide3 {
            background-image: url('/images/banner/sea-1.jpg');
            background-position: center center;
        }

        .slide4 {
            background-image: url('/images/banner/slider4-1.jpg');
            background-position: left center;
        }

        .slide5 {
            background-image: url('/images/banner/slider5-1.jpg');
            background-position: -890px 0;
        }

        .slide6 {
            background-image: url('/images/banner/slider9-1.jpg');
            background-position: -890px 0;
            cursor: pointer;

            h1 {
                .long-content {
                    font-size: 3rem !important;
                }
            }

            // .text-orange {
            //  color: #ff9933 !important;
            // }
            // small {
            //  color: #fff !important;
            // }
        }

        .slide7 {
            background-image: url('/images/banner/slider11-1.jpg');
            background-position: left center !important;

            h1 {
                .long-content {
                    font-size: 40px !important;
                }
            }
        }

        .slide8 {
            background-image: url('/images/banner/slider11-1.png');
            background-position: -890px 0;
        }

        .slide-forex-de-mayo-2022 {
            background-image: url('/images/banner/forexdemayo-mobileslider-2022.jpg');
            background-position: center center !important;
        }

        .slide-forex-christmas {
            background-image: url('/images/banner/forex-christmas-mobile.jpg');
            background-position: center center !important;
        }

        .slide-forex-giveaway {
            background-image: url('/images/banner/forex-giveaway-mobile.jpg');
            background-position: center center !important;
        }

        .slide-covid-safe {
            background-image: url('/images/banner/Forex-Cargo-Covid-Safe-Balikbayan-Box-Mobile.jpg');
            background-position: center center !important;
        }

        .slide-marchflashlight {
            background-image: url('/images/banner/ForexCargo-Marchflashlight-Mobile.jpg');
            background-position: center center !important;
        }

        .slide-localized {
            background-image: url('/images/banner/ForexCargo-localized-number-slider-mobile.jpg');
            background-position: center center !important;
        }

        .slide-balikbayanbox {
            background-image: url('/images/banner/balikbayan-box-businessasusual-australia-lockdown-mobile.jpg');
            background-position: center center !important;
        }

        .slide-xmas-cutoff {
            background-image: url('/images/banner/xmascutoff2022-slider-mobile.jpg');
            background-position: center center !important;
        }

        .slide-xmas-promo {
            background-image: url('/images/banner/fc-mobile-xmaspromo.jpg');
            background-position: center center !important;
        }
        .slide-xmas-closure {
            background-image: url('/images/banner/forex-cargo-holiday-closure-2021-mobile2.jpg');
            background-position: center center !important;

        }
        .slide-job {
            background-image: url('/images/banner/forexcargo-job-opening-forex-team-mobile.jpg');
            background-position: center center !important;
        }
        .slide-xmas-love {
            background-image: url('/images/banner/fc-balikbayanbox-sendlove-mobileslider-NEW.jpg');
            background-position: center center !important;
        }
        .slide-box-promo {
            background-image: url('/images/banner/cargo-promo-luggagescale-mobile.jpg');
            background-position: center center !important;
        }
         .slide-box-price {
            background-image: url('/images/banner/cargo-price-increase-advisory-mobile.jpg');
            background-position: center center !important;
        }
        

    }
}