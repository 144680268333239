
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

html {
	height: 100%;
}

body {
	height: 100%;
	position: relative;
}

.fc-login {
	margin: 40px 0px;
}

.loginpage {
	width: 100% !important;;
}

.logincon {
	background-color: #fff;
	max-width: 350px;
	padding: 20px;
	margin: 0 auto;
	position: relative;
	top: -90px;
}

.fc-pheader-con {
	padding-top: 40px;
	padding-bottom: 80px;
}

@media (max-width: 991px){  
  .fc-login {
		margin: 20px 0px;
	}
}

@media (max-width: 768px){  

   .fc-pheader-con {
		padding-top: 20px;
		padding-bottom: 80px;

		h1, h2, p {
			display: none;
		}
	}

}



