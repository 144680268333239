
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-whychoose {
	margin: 40px 0px;
}

.fc-reasons {
	display: table;

	.content {
		display: table-cell;
		vertical-align: middle;
	}
	
	.image {
		display: table-cell;
		vertical-align: middle;
		img {width: 100%;}
	}
}

.fc-hbtn a { 
	color: #fff;
	text-decoration: none;
 }

.pbody {
	padding: 10px;
}

@media (max-width: 991px){  

  .fc-whychoose {
		margin: 20px 0px;
	}

   .fc-hbtn {
		margin-top: 5px;
	}

}
