/* Stylesheet */

.margin-div {
	margin-bottom: 5px;
}

.w3-black, .w3-hover-black:hover {
    color: #fff!important;
    background-color: #000!important;
}

.w3-button.w3-black.w3-display-left {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%,-50%);
    -ms-transform: translate(-0%,-50%);
    margin-left: 15px;
}
.w3-button.w3-black.w3-display-right {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%,-50%);
    -ms-transform: translate(0%,-50%);
    margin-right: 15px;
}

.w3-btn, .w3-button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w3-btn, .w3-button {
    border: none;
    display: inline-block;
    outline: 0;
    padding: 8px 16px;
    vertical-align: middle;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    background-color: inherit;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
}

button, html [type=button], [type=reset], [type=submit] {
    /*-webkit-appearance: button;*/
}

button, select {
    text-transform: none;
}

button, input {
    overflow: visible;
}

button, input, select, textarea {
    font: inherit;
    margin: 0;
}