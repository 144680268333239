/* Stylesheet */

.fc-login-name {
	width: 30px;
	margin-top: 2px !important;
}

.fc-login {
	margin-top: 15px !important;
}

.fc-pheader-con .fc-pheader-text h2 a {
    color: #f1ac35;
}

.fc-notif.danger {
	border: 1px solid #e14444;
    background-color: #fff2f2;
}
    .fc-notif.danger .n-icon {
	 background-color: #e14444;
	 color: #fff !important;
	}

	.fc-notif.danger span {
		color: #e14444;
	}

.fc-notif.info {
	border: 1px solid #00AEEF;
	background-color: #e4f8ff;
}
	.fc-notif.info .n-icon {
	 background-color: #00AEEF;
	 color: #fff !important;
	}

	.fc-notif.info span {
		color: #00AEEF;
	}