.fc-dda {
  margin: 40px 0px; }

@media (max-width: 991px) {
  .fc-dda {
    margin: 20px 0px; } }

/*# sourceMappingURL=dda.css.map */

.table-areas tr:first-child > td {
    padding: 0;
    padding-top: 10px;
}

.table-areas tr > td:not(:last-child) {
    padding-right: 10px;
}

.table-areas tr:first-child > td > ul.list-group > li.list-group-item:first-child {
    border-radius: 0;
    background-color: rgba(204, 204, 204, 0.14);
}