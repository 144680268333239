
$fc-lblue: #00AEEF;
$fc-dblue: #0055A5;
$fc-orange: #f1ac35; 
$fc-lgray: #f8f8f8;
$fc-mgray: #7f7f7f;
$fc-green: #b2d708;

.fc-oddbox {
  margin: 40px 0px;
}

.fc-pheader-up {
	font-size: 16px;
}

.fc-oddbox-form {
	width: 80%;

	label {
		color: #fff;
		font-weight: normal;
	}
}

input {
	border-radius: 100px;
}


@media (max-width: 991px){  
	.fc-oddbox-form {
		width: 100%;
		margin-left: 0px;
		padding: 0px;
	}
	.fc-oddbox {
	  margin: 20px 0px;
	}
}
